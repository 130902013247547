<template>
  <div class="landing">
    <div class="header__top">
      <div class="container">
        <nav class="menu">
          <ul class="menu__list">
            <li class="menu__item header active-link">
              <a class="menu__link" href="#header">Главная</a>
            </li>
            <li class="menu__item about">
              <a class="menu__link" href="#about">О нас</a>
            </li>
            <li class="menu__item advantages">
              <a class="menu__link" href="#advantages">Преимущества</a>
            </li>
            <li class="menu__item contacts">
              <a class="menu__link" href="#contacts">Контакты</a>
            </li>
            <li class="menu__item logout" v-if="loggedIn">
              <router-link class="menu__link" :to="{ name: 'logout' }" title="Выход"><i class="material-icons">logout</i></router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <section id="header">
      <div class="container">
        <div class="header__inner">
          <div class="header__content">
						<h1 v-if="!loggedIn" style="padding-left: 15%; padding-right: 15%">Автоматизированная система согласования актов выполненных работ и услуг</h1>
            <!-- <h2 v-if="loggedIn" style="font-weight: normal; margin-bottom: 3rem">Добро пожаловать, <strong>{{ user_full_name }}</strong>! <a href="/logout" class="custom-link"><i class='bx bx-exit'></i></a></h2> -->
            <img src="../assets/notebook.png" alt="Notebook" style="position: relative; z-index: 9" v-if="!loggedIn">
            <br>
<!--            <img src="../assets/logo-white.png" style="position: fixed; top: -20px; left: 0; width: 100px; z-index: 10"  v-if="loggedIn">-->
            <a href="/login">
              <button v-if="!loggedIn" class="landing__btn" style="width: 300px">Вход | Регистрация</button>
            </a>
          </div>
          <div class="entry-point-inner" v-if="loggedIn">
            <div class="quick-menu-item">
              <img src="../assets/contracts.svg" alt="">
              <router-link class="landing__btn" :to="{ name: 'contracts' }">Договоры</router-link>
            </div>
            <div class="quick-menu-item">
              <img src="../assets/acts.svg" alt="">
              <router-link class="landing__btn" :to="{ name: 'acts' }">Акты</router-link>
            </div>
            <div class="quick-menu-item">
              <img src="../assets/notifications.svg" alt="">
              <router-link class="landing__btn" :to="{ name: 'notifications' }">Уведомления</router-link>
            </div>
            <div class="quick-menu-item">
              <img src="../assets/undraw_profile_details_re_ch9r.svg" alt="">
              <router-link class="landing__btn" :to="{ name: 'profile' }">Профиль</router-link>
            </div>
            <div class="quick-menu-item">
              <img src="../assets/undraw_texting_re_l11n.svg" alt="">
              <router-link class="landing__btn" :to="{ name: 'messenger' }">Чат</router-link>
<!--              <router-link class="landing__btn" :to="{ name: 'messages' }">Чат</router-link>-->
            </div>
            <div class="quick-menu-item">
              <img src="../assets/undraw_teaching_re_g7e3.svg" alt="">
              <!-- <router-link class="landing__btn" :to="{ name: 'tutorial' }">Обучение</router-link> -->
              <router-link class="landing__btn" :to="{ name: 'tutorial' }">Обучение</router-link>
            </div>
            <div class="quick-menu-item">
              <img src="../assets/undraw_questions_re_1fy7.svg" alt="">
              <router-link class="landing__btn" :to="{ name: 'learning' }">F A Q</router-link>
            </div>
            <div class="quick-menu-item">
              <img src="../assets/undraw_things_to_say_re_jpcg.svg" alt="">
              <!-- <router-link class="landing__btn" :to="{ name: 'developmentpage' }">Предложения</router-link> -->
              <router-link class="landing__btn" :to="{ name: 'offers' }">Предложения</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="about">
      <div class="container">
<!--        <h2 class="section-title">Коротко о нас</h2>-->
        <div class="about__inner">
          <div class="information">
            <h1 class="section-heading text-center">О системе согласования актов выполненных работ и услуг AVR+</h1>
            <p class="section-text text-center" style="font-size: 30px">
              Система <strong>AVR+</strong> предназначена для согласования актов выполненных работ и услуг АО "Эмбамунайгаз"
            </p>
          </div>
<!--          <div class="illustration">-->
<!--            <img src="../assets/about.svg" alt="">-->
<!--          </div>-->
        </div>
      </div>
    </section>

    <section id="advantages">
      <div class="container">
        <h2 class="section-title">Преимущества</h2>
        <div class="advantages__inner">
          <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 1rem; width: 100%">
            <div class="implementation-block wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.2s">
              <i class="bx bx-fast-forward bx-custom-landing" style="color: #6c63ff;"></i>
              <div class="implementation-subtitle">Быстрое обучение</div>
              <div class="implementation-hover-text">
                <span>Полностью автоматизированный процесс внедрения системы. Вам предоставляется конфигурация в готовом виде, которая не требует адаптации или настройки, без дополнительных расходов и трудозатрат на внедрение.</span>
              </div>
            </div>
            <div class="implementation-block wow fadeInUp" data-wow-delay="0.4s">
              <i class="bx bx-git-compare bx-custom-landing" style="color: #6c63ff;"></i>
              <div class="implementation-subtitle">Выбор способа согласования</div>
              <div class="implementation-hover-text">
                <span>
                  Выбирайте способ согласования на этапе создания акта
                </span>
              </div>
            </div>
            <div class="implementation-block wow fadeInUp" data-wow-delay="0.6s">
              <i class="bx bx-filter-alt bx-custom-landing" style="color: #6c63ff;"></i>
              <div class="implementation-subtitle">Фильтрация информации</div>
              <div class="implementation-hover-text">
                <span>
                  Фильтрация информации. Получайте только ту информацию, которая касается Вас.
                </span>
              </div>
            </div>
            <div class="implementation-block wow fadeInUp" data-wow-delay="0.8s">
              <i class="bx bx-user-check bx-custom-landing" style="color: #6c63ff;"></i>
              <div class="implementation-subtitle">Верификация аккаунтов</div>
              <div class="implementation-hover-text">
                <span>
                  Регистрация производится в строго закрытом режиме
                </span>
              </div>
            </div>
            <div class="implementation-block wow fadeInUp" data-wow-delay="1s">
              <i class="bx bx-bell bx-custom-landing" style="color: #6c63ff;"></i>
              <div class="implementation-subtitle">Уведомление о событиях</div>
              <div class="implementation-hover-text">
                <span>
                  Получайте email уведомления о процессе согласования данных прямиком на почту
                </span>
              </div>
            </div>
            <div class="implementation-block wow fadeInUp" data-wow-delay="1.2s">
              <i class="bx bx-check-shield bx-custom-landing" style="color: #6c63ff;"></i>
              <div class="implementation-subtitle">Защита согласованных актов</div>
              <div class="implementation-hover-text">
                <span>
                  Будьте уверены в том, что согласованный акт никак не изменится посторонними лицами
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="contacts">
      <div class="container">
<!--        <h2 class="section-title">Связаться с нами</h2>-->
        <div class="contacts__inner">
        <div class="contacts__item">
            <span>Контакты ответственного сотрудника АО "ЭмбаМунайгаз"</span>
            <div class="contacts__content">
              <span>Шамакова Айнат</span>
              <span>Телефон: <a href="tel:87015779662" class="custom-link">8 701 577 96 62</a></span>
              <span>Рабочий: <a href="tel:87122993155" class="custom-link">8 7122 99 31 55</a></span>
              <span>Внутренний номер: <a href="tel:21455" class="custom-link">21 455</a></span>
              <span>Почта: <a href="mailto:a.shamakova@emg.kmgep.kz" class="custom-link">a.shamakova@emg.kmgep.kz</a></span>
            </div>
          </div>
          <div class="contacts__item">
            <span>Контакты технической поддержки</span>
            <div class="contacts__content">
              <span>Мағзұм Дархан Әлімжанұлы</span>
              <span>Телефон: <a href="tel:87074927144" class="custom-link">8 707 492 71 44</a></span>
              <span>Почта: <a href="mailto:support-avr@prodev.kz" class="custom-link">support-avr@prodev.kz</a></span>
            </div>
          </div>
<!--          <form @keyup.enter="submitContactForm">-->
<!--            <input type="text" v-model="name" placeholder="Введите ваше имя">-->
<!--            <input type="text" v-model="email" placeholder="Введите ваш email">-->
<!--            <textarea v-model="message" placeholder="Ваше сообщение"></textarea>-->
<!--            <button class="landing__btn" @click="submitContactForm">Отправить</button>-->
<!--          </form>-->
        </div>
      </div>
    </section>
    <modal name="info-modal">
      <div class="modal-inner">
        <h3>Добро пожаловать в систему AVR+</h3>
        <div>
          <p>С <b>21.06.2022</b> по <b>24.06.2022</b> система AVR+ будет находиться в режиме отладки, просим извинения за доставленные неудобства.<br> Переход на штатный режим функционирования системы запланирован на <b>25.06.2022г</b></p>
          <br>
          <p>По всем вопросам просим обращаться: </p>
          <p><a href="tel:+77074927144"><b>+7 707 492 71 44</b></a></p>
          <p><a href="mailto:support-avr@prodev.kz"><b>support-avr@prodev.kz</b></a></p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      message: '',
      loggedIn: false,
      is_IMPL: false,
      is_ABP: false,
      is_APPROVER: false,
      is_RATIFICATOR: false,
      is_ECONOMIST: false,
      is_BOOKER: false,
      is_DZOADM: false,
      user_full_name: ""
    }
  },
  methods: {
    submitContactForm() {
      console.log(1234)
      console.log(this.name)
      console.log(this.email)
      console.log(this.message)

    },
    isIMPL() {
      return localStorage.getItem('roles').includes(1);
    },
    isABP() {
      return localStorage.getItem('roles').includes(2);
    },
    isAPPROVER() {
      return localStorage.getItem('roles').includes(3);
    },
    isRATIFICATOR() {
      return localStorage.getItem('roles').includes(4);
    },
    isECONOMIST() {
      return localStorage.getItem('roles').includes(5);
    },
    isBOOKER() {
      return localStorage.getItem('roles').includes(6)
    },
    isDZOADM() {
      return localStorage.getItem('roles').includes(7);
    },
    userFullName() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user["last_name"] + ' ' + user["first_name"];
    },
  },
  mounted() {
    // this.$modal.show("info-modal");
		const sections = document.querySelectorAll('section')
		const navLi = document.querySelectorAll('nav ul li')
    const nav = document.querySelector('.menu')
		window.onscroll = () => {
			let current = ''

      if (scrollY >= 600) {
        nav.classList.add('nav-scrolled')
      } else {
        nav.classList.remove('nav-scrolled')
      }

			sections.forEach(section => {
				const sectionTop = section.offsetTop
				if (scrollY >= sectionTop - 60) {
					current = section.getAttribute('id')
				}
			})

			navLi.forEach(li => {
				li.classList.remove('active-link')
				if (li.classList.contains(current)) {
					li.classList.add('active-link')
				}
			})
		}
	},
  created() {
    this.is_IMPL = this.isIMPL();
    this.is_ABP = this.isABP();
    this.is_APPROVER = this.isAPPROVER();
    this.is_RATIFICATOR = this.isRATIFICATOR();
    this.is_ECONOMIST = this.isECONOMIST();
    this.is_BOOKER = this.isBOOKER();
    this.is_DZOADM = this.isDZOADM();
    this.loggedIn = this.is_IMPL || this.is_ABP || this.is_APPROVER || this.is_RATIFICATOR || this.is_ECONOMIST || this.is_BOOKER || this.is_DZOADM;
    this.user_full_name = this.userFullName();
  }
};
</script>

<style scoped>
*,
*::before,
*::after {
	box-sizing: border-box;
}

.contacts__info {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.contacts__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;
}

.landing {
  background: #e4e9f7;
}

.container {
	max-width: 1220px;
	margin: 0 auto;
	padding: 0 10px;
}

a {
	text-decoration: none;
	color: inherit;
}

button {
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: inherit;
}

.header-block {
	background-color: #fff;
}

.header__inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
  align-items: center;
  min-height: 95vh;
}

.header__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
  position: sticky;
  z-index: 99;
  background: #e4e9f7;
  top: 0;
}

.input-field {
  width: 400px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border-radius: 10px;
}

.menu.nav-scrolled {
  color: black;
}

.menu__list {
	display: flex;
  justify-content: center;
  gap: 5rem;
  list-style-type: none;
  margin-bottom: 0;
}

.menu__item {
	margin-right: 30px;
  padding: 5px;
}

.menu__item.active-link {
  color: #2191FB !important;
}

.menu__item.logout {

}

.menu__link {
	font-size: 18px;
	transition: color .3s;
}

.menu__link:hover {
	color: #2191FB;
}

.header__content {
  margin: 0 auto;
  text-align: center;
}

.landing__btn {
  font-size: 20px;
  width: 200px;
  border: 1px solid #2191FB;
  border-radius: 10px;
  padding: .5rem 2rem;
  color: #2191FB;
  transition: all .3s;
  box-shadow: 0 18px 13px -6px rgba(0, 0, 0,0.2);
  position: sticky;
  background: #e4e9f7;
  text-align: center;

  top: 100px !important;
}

.landing__btn:hover {
  color: #fff;
  background: #2191FB;
  box-shadow: 0 25px 18px -6px rgba(0, 0, 0,0.2);
}

.header__content img {
  margin-bottom: 2rem;
}

.header__content h1 {
  margin-bottom: 2rem;
}


#about {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), url("../assets/home.jpg") center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: #DB5A42; */
  /* text-align: center; */
}

.section-title {
  padding-top: 5rem;
  text-align: center;
}

.about__inner {
	display: flex;
	justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
	/*min-height: 100vh;*/
}

.information {
  /*max-width: 800px;*/
}

.section-text {
  font-size: 20px;
}

.illustration img {
  /* width: 600px; */
}

.section-heading {
  margin-bottom: 2rem;
}


#advantages {
  /* background-color: #666666; */
}

.implementation {
  padding: 30px 0;
  text-align: center;
}

.implementation-block {
  width: 400px;
  height: 200px;
  cursor: pointer;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-style: italic;
  font-family: cursive;
}

.implementation-block i {
  font-size: 60px;
  margin: 25px auto 20px auto;
}

.implementation-hover-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2191FB;
  opacity: 0;
  transition: opacity 0.4s;
  border-radius: 10px;
}
.implementation-block:hover .implementation-hover-text {
  opacity: 1;
}

.implementation-hover-text span {
  display: table;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 25px;
  font-size: 18px;
  color: white;
  font-style: normal;
  font-family: serif;
}

.implementation-title {
  text-align: center;
  color: #083d75;
  text-transform: uppercase;
  margin-bottom: 40px;
}
.implementation img {
  height: 60px;
  margin: 25px auto 20px auto;
  /* opacity: 0.6; */
}
.implementation-subtitle {
  font-size: 22px;
  margin-bottom: 55px;
}

.advantages__inner {
	display: flex;
	justify-content: space-between;
  align-items: center;
}

#contacts {
  background: linear-gradient(to bottom, #e4e9f7 0%, rgba(255, 255, 255, 0.9) 100%), url("../assets/logo-header.png") no-repeat;
  /*background-size: ;*/
  background-position-x: center;
  background-position-y: 90%;
  background-attachment: fixed;
  /*background-size: cover;*/
  /*background-attachment: fixed;*/
  /* background-color: #999999; */
}

.contacts__inner {
  padding-top: 1rem;
	display: flex;
	justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
	min-height: 40vh;
  font-size: 20px;
  padding-bottom: 2rem;
}

.contacts__item {
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
}

.contacts__inner form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.contacts__inner form input,
.contacts__inner form textarea {
  border: 1px solid #666;
  padding: .5rem 1rem;
  width: 100%;
}

.contacts__inner form input:focus,
.contacts__inner form textarea:focus {
  border: 1px solid #2191FB;
  outline: 0;
}

/* Entry point */



.entry-point-inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  /* min-height: 100vh; */
}

.quick-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 20px;
  /*position: relative;*/
  margin-bottom: 2rem;
}

.quick-menu-item img {
  /*max-width: 300px;*/
  max-height: 200px;
  min-height: 200px;
  z-index: 2;

}

</style>
