// import AuthService from '@/service/auth.service'
import router from '../../router.js'
import axios from 'axios'

const currentUser = JSON.parse(localStorage.getItem('user'));
const myUser = currentUser
    ? currentUser
    : null


export default {
    state: {
        user: myUser,
        token: localStorage.getItem('token') || null,
        roles: null
    },
    getters: {
        loggedIn(state) {
            return state.token != null
        },
        // isActiveAccount(state) {
        //     return state.user.ouser.is_active;
        // },
        USER(state) {
            return state.user
        },
        userId(state) {
            return state.user.id
        },
    },
    mutations: {
        retrieveToken(state, token) {
            state.token = token
        },
        destroyToken(state) {
            state.token = null
        },
        set_authorized(state, user) {
            // state.token = true;
            state.user = user
        },
        set_roles(state, roles) {
            // state.token = true;
            let role_list = [];
            roles.forEach(function (item_role, index_role) {
                console.log(index_role);
                role_list.push(item_role.access_level);
            });
            state.roles = role_list;
        },
        logout(state) {
            state.token = null
            state.initialState.loggedIn = false;
        },
        activate_user(state, user) {
            state.user = user
            state.getters.loggedIn = true
        }

    },
    actions: {
        login(context, formData) {

            axios.post('auth/login_ecp/', {
                keyId_sign: formData.keyId_sign,
                alias_sign: formData.alias_sign,
                algorithm_sign: formData.algorithm_sign,
                subjectCn_sign: formData.subjectCn_sign,
                subjectDn_sign: formData.subjectDn_sign,
                issuerCn_sign: formData.issuerCn_sign,
                issuerDn_sign: formData.issuerDn_sign,
                serialNumber_sign: formData.serialNumber_sign,
                certNotAfter_sign: formData.certNotAfter_sign,
                certNotBefore_sign: formData.certNotBefore_sign,
                authorityKeyIdentifier_sign: formData.authorityKeyIdentifier_sign,
                pem_sign: formData.pem_sign,
            })
            .then(response => {
                const token = response.data.token
                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify(response.data.user));
                let role_list = [];
                response.data.roles.forEach(function (item_role, index_role) {
                    console.log(index_role);
                    role_list.push(item_role.access_level);
                });
                localStorage.setItem('roles', JSON.stringify(role_list));
                let role_name_list = "";
                response.data.roles.forEach(function (item_role, index_role) {
                    console.log(index_role);
                    role_name_list += item_role.type + ", ";
                });

                localStorage.setItem('roles_name', role_name_list);
                context.commit('retrieveToken', token)
                context.commit('set_authorized', response.data.user);
                context.commit('set_roles', response.data.roles);

                router.push({
                    name: 'landing'
                })
            })
            .catch(error => {
                if (error.response) {
                    context.dispatch('addNotification', {
                        type: 'error',
                        message: error.response.data.detail,
                    })
                } else if (error.request) {
                    console.error(error.request);
                } else {
                    console.error(error.detail);
                }
            })
        },
        update_ecp_profile({commit}, formData) {
            console.log(commit);
            return axios.post('auth/update_ecp_profile/', {
                keyId_sign: formData.keyId_sign,
                alias_sign: formData.alias_sign,
                algorithm_sign: formData.algorithm_sign,
                subjectCn_sign: formData.subjectCn_sign,
                subjectDn_sign: formData.subjectDn_sign,
                issuerCn_sign: formData.issuerCn_sign,
                issuerDn_sign: formData.issuerDn_sign,
                serialNumber_sign: formData.serialNumber_sign,
                certNotAfter_sign: formData.certNotAfter_sign,
                certNotBefore_sign: formData.certNotBefore_sign,
                authorityKeyIdentifier_sign: formData.authorityKeyIdentifier_sign,
                pem_sign: formData.pem_sign,
            });
        },
        registerForm({commit}, formData) {
            console.log(commit);
            return axios.post('/auth/permissibles/', formData, {
                headers: {
                    'Accept': 'application/json',
                }
            });
        },
        loginWithIin(context, credentials) {
            return new Promise((resolve, reject) => {
                axios.post('auth/login/', {
                        username: credentials.username,
                        password: credentials.password
                    })
                    .then(response => {
                        const token = response.data.token
                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        let role_list = [];
                        response.data.roles.forEach(function (item_role, index_role) {
                            console.log(index_role);
                            role_list.push(item_role.access_level);
                        });
                        localStorage.setItem('roles', JSON.stringify(role_list));
                        let role_name_list = "";
                        response.data.roles.forEach(function (item_role, index_role) {
                            console.log(index_role);
                            role_name_list += item_role.type + ", ";
                        });

                        localStorage.setItem('roles_name', role_name_list);
                        context.commit('retrieveToken', token)
                        context.commit('set_authorized', response.data.user);
                        context.commit('set_roles', response.data.roles);
                        resolve(response)
                    })
                    .then(() => {
                        context.dispatch('addNotification', {
                            type: 'success',
                            message: 'Успешная авторизация',
                        })
                    })
                    .catch(error => {
                        context.dispatch('addNotification', {
                            type: 'error',
                            message: error.response.data.detail,
                        })
                    })
            })
        },
        destroyToken(context) {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('isActive')
            localStorage.removeItem('roles')
            context.commit('destroyToken')
        },
        getUserInfo({commit}, id) {
            console.log(commit);
            return axios.get(`/auth/ouser/${id}/`);
        },
        approve_permissible({commit}, id) {
            console.log(commit);
            return axios.get(`/auth/permissibles/approve/${id}/`);
        },
        disapprove_permissible({commit}, formData) {
            console.log(commit);
            return axios.post(`/auth/permissibles/disapprove/${formData.perm_id}/`, {
                comment: formData.comment,
            });
        },
        password_recovery({commit}) {
            console.log(commit);
            return axios.get(`/auth/password_recovery/`);
        },
        getAllCollegues({commit}) {
            console.log(commit);
            return axios.get('/auth/collegues/');
        },
        getAllImplCompanies({commit}) {
            console.log(commit);
            return axios.get('/auth/companies/');
        },
        getUsersImplByCompany({commit}, formData) {
            console.log(commit);
            return axios.get('/auth/users_impl/', {
                params: {
                    bin: formData.bin,
                }
            });
        },
        updateImplAbpList({commit}, id) {
            console.log(commit);
            return axios.get(`/auth/impl_abp_list/`, {
                params: {
                    company_id: id,
                }
            });
        },
        getAllABPList({commit}) {
            console.log(commit);
            return axios.get('/auth/abp_list/');
        },
        password_change({commit}, formData) {
            console.log(commit);
            return axios.post('auth/password_change/', {
                new_pass: formData.new_pass,
            });
        },
        password_recovery_any({commit}, formData) {
            return axios.post('auth/password_recovery_any/', {
                    username: formData.username,
                });
        },
        // activateUser({commit}, formData) {
        //     return AuthService.activateUser(formData).then((response) => {
        //         localStorage.setItem('user', JSON.stringify(response.data.user));
        //         commit('activate_user', response.data.user);
        //         // router.push('/home');
        //     }).catch((error) => {
        //         console.log(error.response.data);
        //     })
        // },
        // changePassword({commit, dispatch}, formData) {
        //     return AuthService.changePassword(formData).then((response) => {
        //         dispatch("addNotification", {
        //             type: "success",
        //             message: response.data,
        //         });
        //         return response;
        //     }).catch((error) => {
        //         dispatch("addNotification", {
        //             type: "error",
        //             message: error.response.data.message,
        //         })
        //     })
        // }
    },
}
